<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
                    <el-breadcrumb-item>会员卡列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block top">
            <el-row class="total-header">
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">总售出会员卡数量:</el-col>
                        <el-col :span="span10"><span>{{statistice.todayReview}}</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="span12">总充值金额:</el-col>
                        <el-col :span="span10"><span>{{statistice.totalReview}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">今日已售会员卡数量:</el-col>
                        <el-col :span="span10"><span>{{statistice.totalPending}}</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="span12">今日充值金额:</el-col>
                        <el-col :span="span10"><span>{{statistice.totalPending}}</span></el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="">
                            <el-date-picker
                                    style="width: 250px"
                                    v-model="date"
                                    @change="orderDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始"
                                    end-placeholder="截止">
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input
                                    placeholder="请输入门店、BD、商家信息查询"
                                    v-model="where.area"
                            ></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                            >条件筛选
                            </el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    disabled
                                    icon="el-icon-tickets">导出数据
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column prop="createTime" label="门店ID"></el-table-column>
                    <el-table-column prop="planId" label="门店名称"></el-table-column>
                    <el-table-column prop="planName" label="门店手机号"></el-table-column>
                    <el-table-column prop="id" label="BD姓名"></el-table-column>
                    <el-table-column prop="shopName" label="BD手机号"></el-table-column>
                    <el-table-column prop="putDt" label="区代ID"></el-table-column>
                    <el-table-column prop="putTime" label="区代名称"></el-table-column>
                    <el-table-column prop="unitPrice" label="会员卡数量"></el-table-column>
                    <el-table-column prop="settleMethodText" label="总充值金额"></el-table-column>
                    <el-table-column prop="adTypeText" label="总让利金额"></el-table-column>
                    <el-table-column prop="totalLimit" label="服务商总分润金额"></el-table-column>
                    <el-table-column prop="dailyLimit" label="日限额"></el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                    type="text"
                                    class="el-icon-edit"
                                    size="medium"
                                    @click="detail(scope.row)">充值记录
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <el-dialog title="收款列表" :visible.sync="isShow">
            <el-table :data="data">
                <el-table-column property="id" label="充值时间"></el-table-column>
                <el-table-column property="property" label="会员卡ID"></el-table-column>
                <el-table-column property="property" label="用户名称"></el-table-column>
                <el-table-column property="property" label="用户手机号"></el-table-column>
                <el-table-column property="property" label="用户来源"></el-table-column>
                <el-table-column property="property" label="充值金额"></el-table-column>
                <el-table-column property="property" label="会员卡金额"></el-table-column>
                <el-table-column property="property" label="让利比例"></el-table-column>
                <el-table-column property="property" label="服务商分润"></el-table-column>
                <el-table-column property="property" label="店铺分润"></el-table-column>
                <el-table-column property="property" label="平台分润"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                list: [],
                data:[],
                date: "",
                row: {},
                loading: false,
                span: 6,
                span4: 5,
                span12: 12,
                span10: 10,
                dialogEditVisible: false,
                statistice:{
                    "todayReview": 0,
                    "totalReview": 0,
                    "todayPending": 0,
                    "totalPending": 0
                },
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                rolesData: [],
                selectId: "",
                isShow: false,
                where: {
                    startTime:'',//开始时间
                    endTime:'',//结束时间
                    adSpaceId:'',//广告位id
                    settleMethod:'',//结算方式【1-现金 2-慧豆】
                    adType:'',//广告类型【1-视频 2-图文 3-banner】
                    payStatus:'',//0000-已支付 0010-未支付
                    pageNum:1,//分页，起始页
                    pageSize:20,//分页，每页大小
                },
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.advertisementAuditList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            orderDate(obj) {
                if (obj) {
                    this.where.startDate = obj[0];
                    this.where.endDate = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDate = '';
                        this.where.endDate = '';
                    }
                });
            },
            exportData() {
                console.log("exportData");
            },
            getDialog(val) {
                this.dialogEditVisible = val;
            },
            detail(param) {
                if(param){
                    this.isShow = true;
                }
            },
            post(url, data) {
                this.$postFormData(url, data).then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        this.listData();
                    } else {
                        this.$message({
                            type: "error",
                            message: "失败!",
                        });
                    }
                });
            },
            getStatistice(){
                this.$post(this.$api.advertisementReviewStatistice, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
        },
        created() {
            this.listData();
            this.getStatistice();
        },
    };
</script>
<style lang="scss" scoped>
    .total-header {
        margin-bottom: 0px !important;
        margin-top: 15px;

        .el-col {
            margin-right: 15px;

            :last-child {
                margin-right: 0px;
            }
        }
    }

    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .header-form {
        height: 40px;
        padding: 10px 0 10px 0;
    }

    .span4 {
        background: rgb(217, 236, 255);
        font-size: 16px;
        padding: 30px 0;
        text-align: center;
        vertical-align: center;

        span {
            font-size: 18px;
        }
    }

    /deep/ .el-input-number.is-controls-right .el-input__inner {
        padding-left: 2px;
        padding-right: 32px;
    }
    .cover{
        width: 100px;
    }
</style>

